<template>
  <div class="div-drawer-sys-settings">
    <a-spin tip="Loading...">
      <div class="spin-content">
      正在进入中...
      </div>
    </a-spin>
  </div>
</template>

<script>
import store from '@/micro/globalStore'
import { mapState } from 'vuex'
export default {
  // name: "MainDrawer",
  props: ['menuData'],
  data() {
    return {}
  },
  computed: {
    ...mapState(['drawerVisible']),
    drawerData() {
      return this.$store.state.drawerVisible
    },
    isLimitss() {
      return this.$store.state.isLimitss
    },
    indexMenu() {
      const indexMenu = this.menuData.filter(item => item && item.name === '首页')
      if ((indexMenu instanceof Array) && indexMenu.length > 0) {
        return indexMenu[0].children
      } else {
        return []
      }
    }
  },
  watch: {
    layoutIndex(n) {
      this.$store.state.layout = this.devList[n].val
    },
    defaultIndex(n) {
      // this.$store.state.defaultPage = this.indexMenu[n].path
    },
    drawerData: {
      immediate: true,
      handler: function(n) {
        this.drawerWidth = n ? 256 : 0
      }
    },
    isLimitss: {
      immediate: true,
      handler: async function(n) {
        const res = await this.initLayout()
        this.layoutIndex = n ? 1 : res
        this.$store.state.layout = this.devList[this.layoutIndex].val
      }
    }
  },
  created() {
    this.login()
    this.$http('post_author_listConfigUser', { configCode: 'defaultPage' }, true).then(res => {
      if (res.code === 200 && res.data.length > 0) {
        this.defaultIndex = typeof res.data[0].configValue === 'string' && parseInt(res.data[0].configValue) ? parseInt(res.data[0].configValue) : 0
        // 如果configValue存在且可用，高亮显示该值，否则默认第一个
      }
    })
  },
  methods: {
    initLayout() {
      this.login()
      const res = this.$http('post_author_listConfigUser', { configCode: 'layout' }, true).then(res => {
        if (res.code === 200 && res.data.length > 0) {
          return typeof res.data[0].configValue === 'string' && parseInt(res.data[0].configValue) ? parseInt(res.data[0].configValue) : 0
        } else {
          return 0
        }
      })
      setTimeout(() => {
        this.$router.push('/home')
      }, 1000)
      return res
    },
    login() {
      // ?admin,123
      const url = window.location.href
      // console.log('urlrlrl', url)
      const name = url.split('?')[1].split(',')[0]
      // console.log('name', name)
      const pwd = url.split('?')[1].split(',')[1]
      // console.log('pwd', pwd)
      const userName = name
      const userPwd = pwd
      this.$store.dispatch('global/login', (encrypted) => {
        // console.log(encrypted(userPwd))
        return {
          userName: userName,
          passWord: encrypted(userPwd),
          verifyCode: ''
        }
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          // this.$message.success(res.message)
          const global = this.$store.state.global
          store.setGlobalState({
            token: global.token,
            userInfo: global.userInfo
          })
          this.$store.dispatch('global/dyrouters').then(res => {
            this.$store.commit('global/SET_MENU', res.data)
            // this.listConfig()
          })
        } else {
          this.openNotificationWithIcon('error', '登录失败', res.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.warning(err.message)
      })
    },
    setIndex(index) {
      // 设置默认值高亮，放入vuex用于点击房子，同时存入后台用于下次登录使用
      this.$http('post_author_saveOrEditConfigUserInfo', { configCode: 'defaultPage', configValue: String(index) }, true).then(res => {
        if (res.code === 200) {
          this.defaultIndex = index
          this.$store.state.defaultPage = this.indexMenu[index].path
          this.$message.success('默认首页设置成功~')
          // this.$router.push({
          //     path: this.$store.state.defaultPage
          // })
        }
      })
    },
    setLayout(index) {
      this.$http('post_author_saveOrEditConfigUserInfo', { configCode: 'layout', configValue: String(index) }, true).then(res => {
        if (res.code === 200) {
          this.layoutIndex = index
          this.layoutIndexOld = index
          // this.$store.state.layout = index
          this.$message.success('导航设置成功~')
        }
      })
    },
    onClose() {
      this.$store.state.drawerVisible = false
    },
    onChange(e) {
      this.$store.state.layout = e.target.value
    }
  }
}
</script>

<style lang="less" scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 350px;
  text-align: center;
  height: 100%;
}
/deep/ .ant-spin-blur{
  height: 100%;
}
    // 阴影
    /deep/ .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
        box-shadow: -5px 0 5px -5px rgba(24, 31, 36, .24) !important;
    }
    .item{
        display: flex;
        flex-direction: column;
        .tit{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .cont{
            display: flex;
            flex-wrap: wrap;
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px 0;
            }
        }
    }
</style>
